<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Contratos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Contratos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Contratos</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Tarifarios"
                    data-toggle="tab"
                    href="#Tarifarios"
                    v-if="id != null"
                    >Tarifarios</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-9">
                        <div class="row">
                          <div class="form-group col-md-1">
                            <label for="nombre">Id</label>
                            <input
                              type="number"
                              class="form-control form-control-sm font-weight-bold"
                              v-model="form.id"
                              disabled
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label for="fecha_inicial"># Contrato</label>
                            <input
                              type="text"
                              maxlength="30"
                              class="form-control form-control-sm"
                              id="no_contrato"
                              v-model="form.no_contrato"
                              :class="
                                $v.form.no_contrato.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') ||
                                  act == 2
                              "
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="tipo_empresa">Tipo Empresa</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.tipo_empresa"
                              :class="
                                $v.form.tipo_empresa.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @change="limpiarEmpresa()"
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') ||
                                  act == 2
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option value="1">UNICA EMPRESA</option>
                              <option value="2">MULTIEMPRESA</option>
                            </select>
                          </div>
                          <div
                            class="form-group col-md-6"
                            v-if="form.tipo_empresa"
                          >
                            <label for="fecha_final">Empresa</label>
                            <v-select
                              :class="[
                                $v.empresas.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-if="form.tipo_empresa == 2"
                              v-model="empresas"
                              placeholder="Empresas"
                              label="razon_social"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.empresas"
                              :filterable="false"
                              multiple
                              @search="buscarEmpresas"
                              @input="selectEmpresas()"
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') &&
                                  act == 2
                              "
                            ></v-select>
                            <v-select
                              :class="[
                                $v.form.empresa_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-else
                              v-model="empresa"
                              placeholder="Empresa"
                              label="razon_social"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.empresas"
                              :filterable="false"
                              @search="buscarEmpresas"
                              @input="selectEmpresa()"
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') ||
                                  act == 2
                              "
                            ></v-select>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="objeto_contrato"
                              >Objeto de Contrato</label
                            >
                            <select
                              class="form-control form-control-sm"
                              v-model="form.objeto_contrato"
                              :class="
                                $v.form.objeto_contrato.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can('tif.contratos.edit')
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="oc in listasForms.objetos_contrato"
                                :key="oc.numeracion"
                                :value="oc.numeracion"
                              >
                                {{ oc.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="fecha_ini">Fecha Inicio</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="fecha_ini"
                              v-model="form.fecha_ini"
                              :class="
                                $v.form.fecha_ini.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') ||
                                  act == 2
                              "
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="fecha_fin">Fecha Fin</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="fecha_fin"
                              v-model="form.fecha_fin"
                              :class="
                                $v.form.fecha_fin.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFechas"
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') ||
                                  act == 2
                              "
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="valor">Valor</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="valor"
                              v-model="form.valor"
                              :class="
                                $v.form.valor.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') ||
                                  act == 2
                              "
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="estado">Estado</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.estado"
                              :class="
                                $v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="!form.id || estado == 2 || act == 2"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                                :disabled="act == 2"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-md-6">
                            <label for="funcionarios">Admin Contrato</label>
                            <v-select
                              :class="[
                                $v.form.funcionario_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="funcionario"
                              placeholder="Admin Contrato"
                              label="nombres"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.funcionarios"
                              @search="buscarFuncionarios"
                              @input="selectFuncionarios()"
                              :disabled="
                                !$store.getters.can('tif.contratos.edit')
                              "
                            ></v-select>
                          </div>
                          <div class="form-group col-md-6">
                            <label for="observaciones">Observaciones</label>
                            <br />
                            <textarea
                              vtype="date"
                              class="form-control form-control-sm"
                              id="fecha_final"
                              v-model="form.observaciones"
                              rows="2"
                              :disabled="
                                !$store.getters.can('tif.contratos.edit') ||
                                  act == 2
                              "
                            ></textarea>
                          </div>
                          <div class="form-group col-md-5" v-if="form.id">
                            <label for="link_documento"
                              >Copia contrato <sup>(PDF)</sup></label
                            >
                            <div
                              class="form-group"
                              v-if="
                                form.link_documento == '' ||
                                  (form.link_documento == null && act != 2)
                              "
                            >
                              <input
                                type="file"
                                id="link_documento"
                                ref="link_documento"
                                class="form-control-file"
                                accept=".pdf, .PDF"
                                @change="elegirDirectorio()"
                                :disabled="
                                  !$store.getters.can('tif.contratos.edit') ||
                                    act == 2
                                "
                              />
                              <br />
                              <button
                                id="guardar_documento"
                                type="button"
                                @click="saveDocumento()"
                                class="btn btn-success"
                                data-html="true"
                                title="Guardar Documento"
                                data-toggle="tooltip"
                                :disabled="
                                  !$store.getters.can('tif.contratos.edit') ||
                                    act == 2
                                "
                              >
                                <i class="fa fa-save"></i>
                              </button>
                            </div>
                            <div class="form-group">
                              <button
                                class="btn btn-secondary"
                                @click="descargarDocumento()"
                                id="BotonDescargar"
                                v-if="form.link_documento && act != 2"
                                href=""
                              >
                                Descargar
                              </button>
                              <button
                                type="button"
                                class="btn btn-danger"
                                v-if="
                                  $store.getters.can('tif.contratos.delete') &&
                                    form.link_documento &&
                                    act != 2
                                "
                                :disabled="
                                  form.estado == 2 ||
                                    !$store.getters.can('tif.contratos.edit')
                                "
                                @click="destroyContrato()"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div
                          class="card card-outline card-navy col-md-12 m-2 p-2"
                        >
                          <div class="card-header pt-2 pb-2">
                            <h3 class="card-title">
                              <h6>
                                <i class="fas fa-users"></i>
                                Datos de contacto
                              </h6>
                            </h3>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="nombre">Nombre</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                :disabled="act == 2"
                                v-model="form.nombre"
                                :class="
                                  $v.form.nombre.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <label for="identificacion">Teléfono</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              :disabled="act == 2"
                              v-model="form.telefono"
                              :class="
                                $v.form.telefono.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="col-md-12 pt-2 pb-2">
                            <label for="celular">Correo</label>
                            <input
                              type="email"
                              :disabled="act == 2"
                              class="form-control form-control-sm"
                              v-model="form.correo"
                              :class="
                                $v.form.correo.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Tarifarios" v-if="form.id">
                  <div class="card-body table-responsive">
                    <Tarifarios ref="Tarifarios"></Tarifarios>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      (($store.getters.can('tif.contratos.create') ||
                        $store.getters.can('tif.contratos.edit')) &&
                        estado != '2') ||
                        act != 2
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-3">
                <div class="small-box">
                  <div class="inner">
                    <h3 style="font-size: 45px">Valores</h3>
                    <h3 style="font-size: 14px">
                      <br />
                      Valor Presupuestado:
                      <span class="badge badge-pill badge-secondary">
                        {{ form.valor }}
                      </span>
                    </h3>
                    <h3 style="font-size: 14px">
                      Valor Efectuado:
                      <span class="badge badge-pill badge-success">
                        {{ form.valor_efectuado }}
                      </span>
                    </h3>
                    <h3 style="font-size: 14px">
                      <hr />
                      Diferencia:
                      <span class="badge badge-pill badge-dark">
                        {{ form.valor_diferencia }}
                      </span>
                    </h3>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <a href="#" class="small-box-footer"></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import Tarifarios from "./Tarifarios";

export default {
  name: "ContratosForm",
  components: {
    Loading,
    vSelect,
    Tarifarios,
  },
  data() {
    return {
      cargando: false,
      link_documento: null,
      funcionario: null,
      act: 1,
      form: {
        no_contrato: null,
        link_documento: null,
        empresa_id: null,
        objeto_contrato: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        funcionario_id: null,
        observaciones: null,
        nombre: null,
        telefono: null,
        correo: null,
        tipo_empresa: null,
        estado: 1,
      },
      accion: "",
      metodo: "",
      empresa: [],
      empresas: [],
      listasForms: {
        estados: [],
        empresas: [],
        permisos_firmas: [],
        objetos_contrato: [],
        funcionarios: [],
      },
      id: null,
      estado: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    if (this.form.tipo_empresa == 1) {
      return {
        form: {
          no_contrato: {
            required,
          },
          tipo_empresa: {
            required,
          },
          empresa_id: {
            required,
          },
          objeto_contrato: {
            required,
          },
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
          valor: {
            required,
          },
          nombre: {
            required,
            maxLength: maxLength(50),
          },
          telefono: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          correo: {
            required,
            email,
          },
          estado: {
            required,
          },
          funcionario_id: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          no_contrato: {
            required,
          },
          tipo_empresa: {
            required,
          },
          objeto_contrato: {
            required,
          },
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
          valor: {
            required,
          },
          nombre: {
            required,
            maxLength: maxLength(50),
          },
          telefono: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          correo: {
            required,
            email,
          },
          estado: {
            required,
          },
          funcionario_id: {
            required,
          },
        },
        empresas: {
          required,
        },
      };
    }
  },
  methods: {
    init() {
      this.cargando = true;
      this.act = this.$route.params.act;
      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;
      if (this.$route.params.data_edit) {
        this.estado = this.$route.params.data_edit.estado;
      }
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        if (this.form.funcionario_id) {
          this.funcionario = {
            id: this.form.funcionario_id,
            nombres:
              this.form.funcionario.nombres +
              " " +
              this.form.funcionario.apellidos,
          };
        }
        if (this.form.tipo_empresa == 1) {
          this.empresa = {
            id: this.form.empresa_id,
            razon_social: this.form.empresa.razon_social,
          };
        } else {
          this.showEmpresa();
        }
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
      this.cargando = false;
    },

    showEmpresa() {
      axios
        .get("/api/tif/contratos/empresas/" + this.$route.params.data_edit.id)
        .then((response) => {
          this.empresas = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [3],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            me.form.empresa_id = me.listasForms.empresas.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectEmpresa() {
      this.form.empresa_id = "";
      this.form.empresa = {};
      if (this.empresa) {
        this.form.empresa = this.empresa;
        this.form.empresa_id = this.empresa.id;
      }
    },

    selectEmpresas() {
      this.form.empresas = {};
      if (this.empresas) {
        this.form.empresas = this.empresas;
      }
    },

    getObjetoContrato() {
      axios.get("/api/lista/27").then((response) => {
        this.listasForms.objetos_contrato = response.data;
      });
    },

    async buscarFuncionarios(search, loading) {
      if (search != "") {
        loading(true);
        let me = this;
        var url = "api/admin/funcionarios/lista?nombre=" + search;
        await axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.funcionarios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectFuncionarios() {
      this.form.funcionario_id = null;
      if (this.funcionario != null) {
        this.form.funcionario_id = this.funcionario.id;
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/tif/contratos",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data.contrato;
            this.id = response.data.contrato.id;
            this.estado = response.data.contrato.estado;
            this.$swal({
              icon: "success",
              title: "El contrato se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    elegirDirectorio() {
      this.link_documento = this.$refs.link_documento.files[0];
    },

    saveDocumento() {
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_documento", this.link_documento);
      axios
        .post("api/tif/contratos/documento", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "La copia del contrato se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.link_documento = response.data.link_documento;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    descargarDocumento() {
      window.open(this.uri_docs + this.form.link_documento, "_blank");
    },

    destroyContrato() {
      this.$swal({
        title: "Está seguro de eliminar la copia del Contrato?",
        text:
          "Los cambios no se pueden revertir y eliminará las aprobaciones del contrato existentes!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/tif/contratos/destroyContrato/adjunto", {
              params: {
                id: this.id,
                link: this.form.link_documento,
              },
            })
            .then(() => {
              this.form.link_documento = null;
              this.init();
              this.$swal({
                icon: "success",
                title: "El contrato se eliminó correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    limpiarEmpresa() {
      this.form.empresa_id = null;
      this.form.empresa = {};
      this.form.empresas = {};
      this.empresa = {};
      this.empresas = [];
    },

    back() {
      return this.$router.replace("/Tif/Contratos");
    },
  },
  mounted() {
    this.init();
    this.getEstados();
    this.getObjetoContrato();
  },
};
</script>
